import React, { useEffect } from "react"
import "../sass/main.scss"
import {
  Layout,
  SEO,
  HeadSection,
  ImageSection,
  Carousel,
  Scrollable,
  Image,
} from "../components"
import { Row, Col, Container } from "react-bootstrap"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import $ from "jquery"
import Slugify from "slugify"
const WorkforcePage = () => {
  useEffect(() => {
    $(".client").hover(
      function() {
        $(this).addClass("show")
      },
      function() {
        $(this).removeClass("show")
      },
    )
  })
  const headTitle = (): string => {
    return "Satalia Workforce"
  }
  const headSubTitle = (): string => {
    return "Enterprise Workforce Optimisation. Solved at Scale."
  }
  const headDescription = (): string => {
    return `People are our greatest asset. Unlock their full potential with artificial intelligence. There are more ways of allocating 60 employees to 60 projects than there are atoms in our universe. Real-world constraints, such as employee preferences, skills and location only make this problem harder. Manual approaches don't work. Neither does shoe-horning generic products into vastly different organisations.`
  }
  const imageDescription = () => {
    return (
      <Col
        xs="3"
        sm="7"
        md="8"
        lg="9"
        className="z-index-2 mb-150 offset-lg-2 offset-md-2 offset-sm-1"
      >
        <div className="st-block st-block--transparent st-block--no-padding">
          <h3>
            Dynamically optimise the allocation of your people, at scale and on
            demand.{" "}
            <span className="font-weight-medium">
              It's used and celebrated by a global accountancy firm, whose
              people are its business.
            </span>
          </h3>
        </div>
      </Col>
    )
  }

  const imageContent = () => {
    return (
      <Row>
        <Col xs="3" sm="8" md="10" lg="12" xl="9">
          <div className="efficiency__row">
            <p className="font-weight-bold">Improved profitability.</p>
            <p>4% increase in productivity</p>
            <p className="text-inherit">
              Fulfil more demand and increase billable hours by better utilising
              your greatest asset.
            </p>
          </div>
          <div className="efficiency__row">
            <p className="font-weight-bold">Employee satisfaction.</p>
            <p>14% reduction in travel time</p>
            <p className="text-inherit">
              Reduce unnecessary travel, cater for employee preferences and have
              more diverse teams.
            </p>
          </div>
          <div className="efficiency__row">
            <p className="font-weight-bold">Happier customers.</p>
            <p>5% reduction in staff handover</p>
            <p className="text-inherit">
              Make your customers' lives easier and better by always having the
              right person for the job. And by improving continuity in your
              client teams.
            </p>
          </div>
          <Link
            to="/clients/accountancy-firm"
            className="learn-more"
            title="Accountancy Firm"
          >
            View the case study
          </Link>
        </Col>
      </Row>
    )
  }
  const data: any = useStaticQuery(graphql`
    query {
      images: allFile(filter: { relativeDirectory: { eq: "services" } }) {
        edges {
          node {
            id
            publicURL
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
            name
            extension
          }
        }
      }
      caseStudies: allFile(
        filter: { relativeDirectory: { eq: "casestudies/featured_images" } }
      ) {
        edges {
          node {
            id
            publicURL
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
            name
            extension
          }
        }
      }
      placeholder: file(relativePath: { eq: "placeholder.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      clients: allClient(
        limit: 3
        filter: {
          client_category_id: { eq: 2 }
          show_in_category_page: { eq: 1 }
        }
      ) {
        edges {
          node {
            id
            title
            logo
            industry
            testimonial_commenter_position
            testimonial_commenter
            testimonial_comment
            featured_image
          }
        }
      }
    }
  `)
  const getImage = (imageName: string) => {
    let image = data.images.edges.find((x: any) => x.node.name === imageName)
    if (!image) {
      image = data.placeholder.childImageSharp.fluid
      return image
    }
    return image ? image.node.childImageSharp.fluid : null
  }
  return (
    <Layout step={2}>
      <SEO
        title="Satalia Workforce | Enteprise Workforce Optimisation"
        description="Satalia Workforce automates and optimises the allocation of people to their work. Improves profitability, employee and customer satisfaction."
        twitterTitle="Satalia Workforce | Enteprise Workforce Optimisation"
        twitterDescription="Satalia Workforce optimises the allocation of people to their work. Improves yield, employee and customer satisfaction."
        openGraphTitle="Satalia Workforce | Enteprise Workforce Optimisation"
        openGraphDescription="Enterprise workforce optimisation. At scale, and on demand."
      />
      <Container>
        <section id="satalia-delivery" className="mb-95">
          <HeadSection
            title={headTitle()}
            subtitle={headSubTitle()}
            description={headDescription()}
          ></HeadSection>
        </section>
        <section className="mb-285" id="efficiency">
          <ImageSection
            customClass="efficiency--unified"
            description={imageDescription()}
            content={imageContent()}
            image="satalia-workforce"
            align="end"
            aos="fade-right"
          ></ImageSection>
        </section>
        <section id="features" className="mb-285">
          <Row className="justify-content-center align-items-center">
            <Col xs="3" sm="8" md="10" lg="6" xl="6" className="mb-45">
              <Carousel activeIndex={0} autoPlay={true}>
                <Row className="justify-content-center">
                  <Col xs="3" sm="6" md="6" lg="12" xl="12">
                    <Carousel.Title key="title">Features</Carousel.Title>
                  </Col>
                </Row>
                <div className="st-block">
                  <Carousel.Tabs key="tabs">
                    <Row className="justify-content-sm-center">
                      <Col
                        xs="3"
                        sm="2"
                        md="2"
                        lg="4"
                        xl="4"
                        className="st-carousel__tab-column"
                      >
                        <Carousel.Tab>
                          <h3>
                            Performance
                            <br /> at scale
                          </h3>
                        </Carousel.Tab>
                      </Col>
                      <Col
                        xs="3"
                        sm="2"
                        md="2"
                        lg="4"
                        xl="4"
                        className="st-carousel__tab-column"
                      >
                        <Carousel.Tab>
                          <h3>
                            Configurable
                            <br /> product
                          </h3>
                        </Carousel.Tab>
                      </Col>
                      <Col
                        xs="3"
                        sm="2"
                        md="2"
                        lg="4"
                        xl="4"
                        className="st-carousel__tab-column"
                      >
                        <Carousel.Tab>
                          <h3>
                            Dynamic
                            <br /> schedules
                          </h3>
                        </Carousel.Tab>
                      </Col>
                    </Row>
                    <Carousel.Navigations>
                      <li
                        className="st-carousel__navigation st-carousel__navigation--left st-carousel__navigation--disabled"
                        data-navigate="previous"
                      ></li>
                      <li
                        className="st-carousel__navigation st-carousel__navigation--right"
                        data-navigate="next"
                      ></li>
                    </Carousel.Navigations>
                  </Carousel.Tabs>
                  <Row className="justify-content-center">
                    <Col xs="3" sm="6" md="6" lg="12" xl="12">
                      <Carousel.Contents key="contents">
                        <Carousel.Content>
                          <p>
                            Satalia Workforce uses our SolveEngine - our
                            repository of constantly updated, state-of-the-art
                            algorithms. Better algorithms results in better
                            schedules. It’s built for the cloud, and is proven
                            to perform at scale.
                          </p>
                        </Carousel.Content>
                        <Carousel.Content>
                          <p>
                            Instead of shoe-horning a generic product into a
                            vastly complex operation, we fine-tune our core
                            technologies, and rapidly develop custom constraints
                            to solve 100% of the problem. The performance of a
                            custom-build, without the delay.
                          </p>
                        </Carousel.Content>
                        <Carousel.Content>
                          <p>
                            Satalia Workforce is dynamic, producing schedules
                            that adapt and optimise for an ever changing world.
                            As your workforce and operation changes, so do your
                            schedules.
                          </p>
                        </Carousel.Content>
                      </Carousel.Contents>
                    </Col>
                  </Row>
                </div>
              </Carousel>
            </Col>
            <Col xs="3" sm="8" md="8" lg="6" xl="6" data-aos="fade-left">
              <div className="st-block st-block--has-top-padding">
                <Img loading="eager" fluid={getImage("workforce-ui")} />
              </div>
            </Col>
          </Row>
        </section>
        <section id="delivery-analysis" className="mb-285">
          <Row>
            <Col
              xs="3"
              sm="8"
              md="8"
              lg="9"
              xl="9"
              className="z-index-2 mb-150"
            >
              <div className="st-block st-block--transparent st-block--no-padding">
                <h3>
                  We build custom machine learning modules that enhance data
                  quality,{" "}
                  <span className="font-weight-medium">
                    and provide actionable insights into your workforce and its
                    effectiveness.
                  </span>
                </h3>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="3" sm="8" md="10" lg="12" xl="9">
              <Row>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium">Skills Profiling</p>
                    <p>
                      Uses state-of-the-art network analysis techniques to truly
                      understand the skills and capabilities of the workforce.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium">Demand Forecasting</p>
                    <p>
                      Uses machine learning to predict demand and produce better
                      schedules, improving the effectiveness of your people.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium">P2P Decision Making</p>
                    <p>
                      Introduce cutting-edge peer-to-peer techniques to
                      decentralise and distribute information.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium">Shift Pattern Analysis</p>
                    <p>
                      Predicts shift patterns that better match supply with
                      demand. Improves employee effectiveness and satisfaction.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium">
                      Social Network Analysis
                    </p>
                    <p>
                      Understand your people, their relationship and behaviors,
                      using world leading organisational psychology.
                    </p>
                  </div>
                </Col>
                <Col xs="3" sm="4" md="5" lg="4" xl="4">
                  <div className="st-block pb-25">
                    <p className="font-weight-medium">
                      Diversity and Inclusion
                    </p>
                    <p>
                      Use network analysis to ensure your meeting your diversity
                      and inclusion target, and maximizing the effectiveness of
                      your teams.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <section id="how-it-works" className="mb-285">
          <Row className="justify-content-end">
            <Col xs="3" sm="8" md="10" lg="12" xl="12">
              <Row>
                <Col
                  xs="3"
                  sm="4"
                  md="3"
                  lg="3"
                  xl="3"
                  className="mb-45 offset-lg-3"
                  data-aos="fade-up"
                  data-aos-delay="0"
                >
                  <div className="st-block">
                    <h2>How it works?</h2>
                    <p>
                      We use agile methodologies, and work with you throughout
                      to fine-tune the product to ensure peak performance.
                    </p>
                    <Link className="learn-more" to="/contact" title="Contact">
                      Get in touch
                    </Link>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xs="3"
                  sm="4"
                  md="5"
                  lg="3"
                  xl="3"
                  data-aos="fade-up"
                  data-aos-delay="0"
                >
                  <div className="st-block">
                    <Img
                      loading="eager"
                      fluid={getImage("workforce-scope")}
                      style={{ paddingBottom: "33.2%" }}
                    />

                    <p className="font-weight-medium">Scope and model</p>
                    <p>
                      We scope your objectives, constraints, architecture, data
                      and security requirements. Then we create a mathematical
                      representation of your exact problem.
                    </p>
                  </div>
                </Col>
                <Col
                  xs="3"
                  sm="4"
                  md="5"
                  lg="3"
                  xl="3"
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <div className="st-block">
                    <Img
                      loading="eager"
                      fluid={getImage("workforce-optimisation")}
                      style={{ paddingBottom: "33.2%" }}
                    />

                    <p className="font-weight-medium">
                      Optimisation development
                    </p>
                    <p>
                      We implement the initial optimisation model using sample
                      data. We compare our schedules against your historical
                      ones, and evaluate against key metrics.
                    </p>
                  </div>
                </Col>
                <Col
                  xs="3"
                  sm="4"
                  md="5"
                  lg="3"
                  xl="3"
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  <div className="st-block">
                    <Img
                      loading="eager"
                      fluid={getImage("workforce-pilot")}
                      style={{ paddingBottom: "33.2%" }}
                    />
                    <p className="font-weight-medium">
                      Pilot and productionise
                    </p>
                    <p>
                      We run a pilot on a small, subset of your business. We
                      start integrating the system with your existing data
                      sources.
                    </p>
                  </div>
                </Col>
                <Col
                  xs="3"
                  sm="4"
                  md="5"
                  lg="3"
                  xl="3"
                  data-aos="fade-up"
                  data-aos-delay="900"
                >
                  <div className="st-block">
                    <Img
                      loading="eager"
                      fluid={getImage("workforce-fullrollout")}
                      style={{ paddingBottom: "33.2%" }}
                    />

                    <p className="font-weight-medium">Full rollout</p>
                    <p>
                      We make any required changes, and start rolling out across
                      the business, monitoring throughout to ensure a smooth
                      rollout.
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
      </Container>
      <Container fluid>
        <section id="case-studies" className="pb-75">
          <Container className="p-0">
            <h2>Case studies</h2>
          </Container>
          <Scrollable
            itemSelector=".case-studies__item"
            section="#case-studies"
            leftIndex={0}
          >
            <Row className="case-studies__items st-scroll">
              <div className="scroll-padding"></div>
              {data.clients.edges.map((client, index) => (
                <div className="case-studies__item" key={index}>
                  <ClientItems client={client.node}></ClientItems>
                </div>
              ))}
              <div className="scroll-padding"></div>
            </Row>
          </Scrollable>
        </section>
      </Container>
    </Layout>
  )
}

const ClientItems = ({ client }) => {
  return (
    <div className="client">
      <div className="st-block">
        <Image
          path={client.featured_image}
          isSquare={true}
          alt={"Client | " + client.title}
        />
        <div className="client__content">
          <Row className="h-100">
            <Col xl="12" className="mt-auto">
              <span className="client__category text-white">
                {client.industry}
              </span>
              <h3 className="text-white">{client.title}</h3>
              <div className="client__comment">
                <p>{client.testimonial_comment}</p>
                <p className="font-weight-medium client__commenter">
                  {client.testimonial_commenter}
                </p>
                <Link
                  to={"/clients/" + Slugify(client.title.toLowerCase())}
                  className="learn-more"
                  title={client.title}
                >
                  Read more
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}
export default WorkforcePage
